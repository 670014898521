.waves-container {
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: -1;

  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 35s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 17s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 20s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 23s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 40s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }
}
