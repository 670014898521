body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
#root {
  display: flex;
  /* overflow: scroll; */
}
a {
  color: hsl(187, 100%, 30%);
}
a:active {
  color: hsl(290, 52%, 47%);
}
a:visited {
  color: hsl(230, 52%, 47%);
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.loader {
  padding: 30px;
  opacity: 0.8;
}

.invisible-metadata {
  visibility: hidden;
  height: 0;
  width: 0;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Light.ttf);
  font-weight: 200;
}
